var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "models-page" },
    [
      _c(
        "section",
        {
          staticClass: "border-bottom bg-lighter md-up:sticky-top",
          staticStyle: { top: "70px" }
        },
        [
          _c("div", { staticClass: "container py-3" }, [
            _c(
              "nav",
              { staticClass: "nav sm-down:flex-column" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                    attrs: { to: "/round/post" }
                  },
                  [
                    _c("i", { staticClass: "i-package float-left" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("new_round")))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-sm btn-light text-left d-block sm-down:d-none",
                    attrs: {
                      to: {
                        name: _vm.user_id ? "User_Map" : "Rounds_Map",
                        params: { user_id: _vm.user_id }
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "i-map float-left" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("open_map")))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-sm btn-primary text-left d-block sm-down:w-fill",
                    attrs: { to: "/rounds" }
                  },
                  [
                    _c("i", { staticClass: "i-grid float-left" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("open_list")))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-sm btn-light text-left d-block sm-down:d-none",
                    attrs: { to: "/rounds/fav" }
                  },
                  [
                    _c("i", { staticClass: "i-star-solid float-left" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("round_favs")))])
                  ]
                ),
                _vm._v(" "),
                _vm.selected.length > 0
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "ml-auto btn btn-sm btn-danger p-0 d-block sm-down:w-fill",
                        attrs: { "data-click": "ignore" },
                        on: { click: _vm.delete_confirm }
                      },
                      [_c("i", { staticClass: "i-trash" })]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("section", [
        _c(
          "div",
          { staticClass: "container py-5" },
          [
            _vm.list.length
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.list, function(round) {
                    return _c(
                      "div",
                      {
                        key: round.idt_round,
                        ref: "col-" + round.idt_round,
                        refInFor: true,
                        staticClass:
                          "xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
                      },
                      [
                        _c(
                          "keep-alive",
                          [
                            _vm._o(
                              _c("round-card", {
                                attrs: { data: round, selectable: "" },
                                on: {
                                  "select-force": _vm.onSelectForce,
                                  remove: _vm.onRemove,
                                  select: _vm.onSelect
                                }
                              }),
                              0,
                              round.idt_round
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("mega-loading", {
              attrs: { identifier: _vm.loadingId },
              on: { infinite: _vm.loadPage }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "delete-confirm",
        {
          ref: "delete-confirm",
          attrs: {
            "data-click": "ignore",
            title: _vm.$t("round_del_title"),
            text: _vm.$t("round_del_confirm"),
            rounds: _vm.selected
          },
          on: { confirm: _vm.delete_rounds }
        },
        _vm._l(_vm.selected, function(id, i) {
          return _c("span", { key: i, staticClass: "badge m-2" }, [
            _vm._v("#" + _vm._s(id))
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
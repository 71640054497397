<template>
  <main class="models-page">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    >
      <div class="container py-3">
        <nav class="nav sm-down:flex-column">
          <router-link
            to="/round/post"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
          >
            <i class="i-package float-left"></i>
            <span>{{ $t("new_round") }}</span>
          </router-link>

          <router-link
            :to="{
              name: user_id ? 'User_Map' : 'Rounds_Map',
              params: { user_id: user_id }
            }"
            class="btn btn-sm btn-light text-left d-block sm-down:d-none"
          >
            <i class="i-map float-left"></i>
            <span>{{ $t("open_map") }}</span>
          </router-link>

          <router-link
            to="/rounds"
            class="btn btn-sm btn-primary text-left d-block sm-down:w-fill"
          >
            <i class="i-grid float-left"></i>
            <span>{{ $t("open_list") }}</span>
          </router-link>

          <router-link
            to="/rounds/fav"
            class="btn btn-sm btn-light text-left d-block sm-down:d-none"
          >
            <i class="i-star-solid float-left"></i>
            <span>{{ $t("round_favs") }}</span>
          </router-link>

          <span
            class="ml-auto btn btn-sm btn-danger p-0 d-block sm-down:w-fill"
            data-click="ignore"
            @click="delete_confirm"
            v-if="selected.length > 0"
          >
            <i class="i-trash"></i>
          </span>
        </nav>
      </div>
    </section>

    <section>
      <div class="container py-5">
        <div class="row" v-if="list.length">
          <div
            class="xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
            v-for="round in list"
            :ref="'col-' + round.idt_round"
            :key="round.idt_round"
          >
            <keep-alive>
              <round-card
                v-once
                :data="round"
                selectable
                @select-force="onSelectForce"
                @remove="onRemove"
                @select="onSelect"
              />
            </keep-alive>
          </div>
        </div>

        <mega-loading
          :identifier="loadingId"
          @infinite="loadPage"
        ></mega-loading>
      </div>
    </section>

    <delete-confirm
      ref="delete-confirm"
      data-click="ignore"
      :title="$t('round_del_title')"
      :text="$t('round_del_confirm')"
      @confirm="delete_rounds"
      :rounds="selected"
    >
      <span class="badge m-2" v-for="(id, i) in selected" :key="i"
        >#{{ id }}</span
      >
    </delete-confirm>
  </main>
</template>

<script>
import RoundCard from "../Rounds/components/round-card";
import DeleteConfirm from "../../components/delete-confirm";
// import PlaceholderRoundCard from "../../placeholders/round-card";

export default {
  name: "UserRoundsPage",
  props: {
    user_id: [Number, String],
    arr_id: [Number, String]
  },
  data() {
    return {
      selected: [],
      keys: {},
      loaded: false,
      loading: true,
      showModal: false,
      multiple: false,
      loadingId: +new Date(),
      list: []
    };
  },
  watch: {
    $route() {
      // this.$root.list = [];
      this.loadingId += 1;
    }
  },
  computed: {
    // list() {
    //   return this.$root.list;
    // }
  },
  created() {
    // if (!this.$root["history"].includes(this.$route.name))
    //   this.$root["list"] = [];
  },
  mounted() {
    this.$navbar.name = this.$t("rounds");

    document.addEventListener("click", e => {
      let p = e.target;

      while (p) {
        if (
          (p && p.dataset.type === "round-card") ||
          (p && p.dataset.click === "ignore")
        )
          return;

        p = p.parentElement;
      }

      this.selected = [];
    });

    document.addEventListener("keyup", this.onKeyup);
    document.addEventListener("keydown", this.onKeydown);
  },
  beforeDestroy() {
    document.removeEventListener("click", () => {});

    document.removeEventListener("keyup", this.onKeyup);
    document.removeEventListener("keydown", this.onKeydown);
  },
  methods: {
    onKeyup(e) {
      this.keys[e.key] = false;
    },
    onKeydown(e) {
      this.keys[e.key] = true;
    },

    delete_confirm() {
      this.$refs["delete-confirm"].open();
    },

    onSelect(roundId) {
      if (!this.keys["Control"]) this.selected = [];

      if (!this.selected.includes(roundId)) this.selected.push(roundId);
    },

    onRemove(roundId) {
      if (!this.keys["Control"]) this.onSelect(roundId);
      else this.selected = this.selected.filter(r => r !== roundId);
    },

    onSelectForce(roundId) {
      if (!this.selected.includes(roundId)) this.selected.push(roundId);
      else this.selected = this.selected.filter(r => r !== roundId);
    },

    async delete_rounds() {
      for (const id of this.selected) {
        await this.$api.v2.delete("/round", { params: { idt_round: id } });
        this.list = this.list.filter(el => el.idt_round !== id);
        this.$refs["col-" + id][0].outerHTML = "";
      }

      this.selected.splice(0, this.selected.length);

      this.$refs["delete-confirm"].close();
    },

    loadPage($state) {
      let params = {
        limit: 20,
        idt_arp: this.arr_id,
        idt_user: this.user_id,
        offset: this.list.length,
        is_location_hidden: true,
        sn_id: this.$store.getters.selectedSnId
      };

      this.$api.v2
        .get("/rounds", { params })
        .then(({ data }) => {
          this.length = data.count;
          this.$navbar.name = `${this.$t("rounds")} (${this.length})`;

          if (data.rounds.length) {
            setTimeout(() => {
              if ($state) $state.loaded();
            }, 500);
            this.list.push(...data.rounds);
          } else $state.complete();
        })
        .catch(({ response }) => {
          if (response.status === 404) $state.complete();
          else this.$alert.danger("Oops.. Server error");
        });
    }
  },
  components: {
    RoundCard,
    DeleteConfirm
  }
};
</script>
